export function getPaymentRequestFromQueryParams(queryParams: URLSearchParams): {provider: string, externalId: string, context?: Record<string, string>} {
  const sessionId = queryParams.get('sessionId');
  const paymentIntentId = queryParams.get('payment_intent');
  const token = queryParams.get('token');
  const orderId = queryParams.get('orderId');
  const gatewayReference = queryParams.get('gatewayReference');
  const transactionReference = queryParams.get('transactionReference');
  const giftCard = queryParams.get('gc');

  if (giftCard) {
    return {
      provider: 'giftcard',
      externalId: giftCard,
    }
  } else if (sessionId) {
    return {
      provider: 'windcave',
      externalId: sessionId,
    }
  } else if (paymentIntentId) {
    return {
      provider: 'stripe',
      externalId: paymentIntentId,
    }
  } else if (orderId && token) {
    return {
      provider: 'zip',
      externalId: orderId,
    }
  } else if (orderId) {
    return {
      provider: 'paypal',
      externalId: orderId,
    }
  } else if (token) {
    return {
      provider: 'laybuy',
      externalId: token,
    }
  }  else if (gatewayReference && transactionReference) {
    return {
      provider: 'gem',
      externalId: transactionReference,
      context: {
        gatewayReference
      }
    }
  }else {
    throw new Error('No valid query parameter provided');
  }
}
