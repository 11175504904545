import PlacesAutocomplete from "./PlacesAutocomplete";
import React, {useState, useEffect} from "react";
import {Transition} from "@headlessui/react";
import {IShippingAddress} from "../types/types";
import TextInput from "./TextInput";
import * as yup from "yup";
import {ValidationError} from "yup";

interface IAddressInputProps {
  value: Partial<IShippingAddress> | null;
  onChange: (value: IShippingAddress | null) => void;
  hideOptIn?: boolean;
  errors?: Record<string, string[]>;
}

const addressSchema = yup.object({
  firstName: yup.string().required('Please enter your first name'),
  lastName: yup.string().required('Please enter your last name'),
  address1: yup.string().required('Please enter your address'),
  address2: yup.string().nullable(),
  city: yup.string().required('Please enter your city'),
  province: yup.string().required('Please enter your province'),
  country: yup.string().required('Please enter your country'),
  zip: yup.string().required('Please enter your post code'),
});

const isValidAddress = (addressData: any): addressData is IShippingAddress => {
  try {
    addressSchema.validateSync(addressData, { abortEarly: false });
    return true;
  } catch (e) {
    return false;
  }
};

const AddressInput = ({value, onChange, hideOptIn = false, errors = {}} : IAddressInputProps) => {
  const [address, setAddress] = useState<Partial<IShippingAddress> | null>(value);
  const [showFullAddressForm, setShowFullAddressForm] = useState(false);
  const [validationErrors, setValidationErrors] = useState<Record<string, string[]>>({});


  useEffect(() => {
    if (!value) {
      return;
    }
    setShowFullAddressForm(true);
  }, [value]);

  useEffect(() => {
    if (!address) {
      return;
    }
    if (isValidAddress(address)) {
      onChange(address);
    }
  }, [address, onChange]);

  useEffect(() => {
    if ('shippingAddress' in errors) {
      addressSchema.validate(address, { abortEarly: false })
        .catch((validationError: ValidationError) => {
          const formErrors : Record<string, string[]> = {};

          if (validationError.inner) {
            validationError.inner.forEach((error: ValidationError | string) => {
              if (typeof error === 'string') {
                return;
              }
              if (error.path) {
                formErrors[error.path] = [error.message];
              }
            });
          }

          setValidationErrors(existingErrors => ({
            ...existingErrors,
            ...formErrors
          }));
        });
    }
  }, [address, errors]);

  const handleAddressAutocomplete = (value: Partial<IShippingAddress> | null) => {
    if (!value) {
      setAddress({...address,
        address1: '',
        address2: null,
        city: '',
        province: '',
        zip: '',
      });
      onChange(null);
    } else {
      setAddress({...address, ...value});
      setShowFullAddressForm(true);
    }
    setValidationErrors({});
  }

  const validateInput = (event : React.FocusEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => {
    addressSchema.validateAt(event.target.name, {[event.target.name] : event.target.value}).catch((error: ValidationError) => {
      setValidationErrors({
        ...validationErrors,
        [event.target.name]: [error.message]
      });
    });
  }

  function clearErrors(name: string) {
    const internalValidataionErrors = {...validationErrors};
    delete internalValidataionErrors[name];
    setValidationErrors(internalValidataionErrors);
  }

  const handleAddressChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    clearErrors(event.target.name);
    const {name, value} = event.target;
    const updatedAddress = {...address, [name]: value};
    setAddress(updatedAddress);
  }

  return (
    <fieldset>
      <div className="relative flex gap-3 mt-2">
        <div className="w-full">
          <TextInput
            type="text"
            name="firstName"
            label="First name"
            autoComplete="given-name"
            placeholder="First name"
            value={address?.firstName ?? ''}
            errors={validationErrors}
            onChange={handleAddressChange}
            onBlur={validateInput}
          />
        </div>
        <div className="w-full">
          <TextInput
            type="text"
            name="lastName"
            label="Last name"
            autoComplete="family-name"
            placeholder="Last name"
            value={address?.lastName ?? ''}
            errors={validationErrors}
            onChange={handleAddressChange}
            onBlur={validateInput}
          />
        </div>
      </div>
      <div className="mt-2 ">
        <div>
          <label htmlFor="address" className="sr-only">
            Address
          </label>
          <PlacesAutocomplete name="address" initialValue={address?.address1 ?? ''} onChange={handleAddressAutocomplete}/>
          {validationErrors.address1 && validationErrors.address1.map((message, index) => (
            <p className="mt-2 text-sm text-red-600" id="address1-autocomplete-error" key={index}>
              {message}
            </p>
          ))}
        </div>
        <div className="overflow-hidden">
          <Transition
            show={showFullAddressForm}
            enter="transition ease duration-500 transform"
            enterFrom="opacity-0 -translate-y-12"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease duration-300 transform"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 -translate-y-12"
          >
            <div className="">
              <TextInput
                type="hidden"
                name="address1"
                label="Address"
                autoComplete="address-line1"
                placeholder="Address"
                onChange={handleAddressChange}
                onBlur={validateInput}
                value={address?.address1 ?? ''}
                errors={validationErrors}
              />
              <TextInput
                type="text"
                name="address2"
                label="Apartment, suite, etc."
                autoComplete="address-line2"
                placeholder="Apartment, suite, etc."
                hideLabel={true}
                onChange={handleAddressChange}
                onBlur={validateInput}
                value={address?.address2 ?? ''}
                errors={validationErrors}
              />
              <div className="flex gap-3 items-start">
                <div className="flex-col w-full">
                  <TextInput
                    type="text"
                    name="city"
                    label="City"
                    hideLabel={true}
                    autoComplete="address-level2"
                    placeholder="City"
                    onChange={handleAddressChange}
                    onBlur={validateInput}
                    value={address?.city ?? ''}
                    errors={validationErrors}
                  />
                </div>
                <div className="flex-col w-full">
                  <TextInput
                    type="text"
                    name="province"
                    label="Province"
                    hideLabel={true}
                    autoComplete="address-level3"
                    placeholder="Province"
                    onChange={handleAddressChange}
                    onBlur={validateInput}
                    value={address?.province ?? ''}
                    errors={validationErrors}
                  />
                </div>
                <div className="flex-col w-full">
                  <TextInput
                    type="text"
                    name="zip"
                    label="Postcode"
                    hideLabel={true}
                    autoComplete="postal-code"
                    placeholder="Postcode"
                    onChange={handleAddressChange}
                    onBlur={validateInput}
                    value={address?.zip ?? ''}
                    errors={validationErrors}
                  />
                </div>
              </div>
            </div>
          </Transition>
        </div>
        <div className="mt-2">
          <label htmlFor="country" className="sr-only block text-sm font-medium text-gray-700">
            Country
          </label>
          <select
            id="country"
            name="country"
            autoComplete="country-name"
            className="relative block p-3 rounded-full w-full border-0 bg-transparent text-gray-900 ring-1 ring-inset ring-gray-300 focus:z-10 focus:ring-4 focus:ring-inset focus:ring-gray-300 sm:text-sm sm:leading-6"
            value={address?.country ?? 'NZ'}
            onChange={(event) => {handleAddressChange(event);validateInput(event)}}
          >
            <option value="NZ">New Zealand</option>
          </select>
        </div>
      </div>
      <div className="mt-2">
        <label htmlFor="address2" className="sr-only block text-sm font-medium text-gray-700">
          Phone number
        </label>
        <input
          type="text"
          name="phone"
          id="phone"
          autoComplete="phone"
          className="block w-full rounded-full p-3 border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-4 focus:ring-inset focus:ring-gray-300 sm:text-sm sm:leading-6"
          placeholder="Phone number"
          value={address?.phone ?? ''}
          onChange={handleAddressChange}
        />
        {!hideOptIn && (
          <div className="mt-2 relative flex items-start">
            <div className="flex h-6 items-center">
              <input
                id="sms_marketing_opt_in"
                name="sms_marketing_opt_in"
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 text-gray-600 focus:ring-gray-600"
              />
            </div>
            <div className="ml-3 text-sm leading-6">
              <label htmlFor="sms_marketing_opt_in" className="font-normal text-gray-900">
                Text me with news and offers
              </label>
            </div>
          </div>
        )}
      </div>
    </fieldset>
  );
}

export default AddressInput;
